exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-about-zh-tsx": () => import("./../../../src/pages/about-zh.tsx" /* webpackChunkName: "component---src-pages-about-zh-tsx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-digital-bullet-journal-app-tsx": () => import("./../../../src/pages/digital-bullet-journal-app.tsx" /* webpackChunkName: "component---src-pages-digital-bullet-journal-app-tsx" */),
  "component---src-pages-features-goal-tsx": () => import("./../../../src/pages/features/goal.tsx" /* webpackChunkName: "component---src-pages-features-goal-tsx" */),
  "component---src-pages-features-goal-zh-tsx": () => import("./../../../src/pages/features/goal-zh.tsx" /* webpackChunkName: "component---src-pages-features-goal-zh-tsx" */),
  "component---src-pages-features-habits-tsx": () => import("./../../../src/pages/features/habits.tsx" /* webpackChunkName: "component---src-pages-features-habits-tsx" */),
  "component---src-pages-features-habits-zh-tsx": () => import("./../../../src/pages/features/habits-zh.tsx" /* webpackChunkName: "component---src-pages-features-habits-zh-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-notes-tsx": () => import("./../../../src/pages/features/notes.tsx" /* webpackChunkName: "component---src-pages-features-notes-tsx" */),
  "component---src-pages-features-notes-zh-tsx": () => import("./../../../src/pages/features/notes-zh.tsx" /* webpackChunkName: "component---src-pages-features-notes-zh-tsx" */),
  "component---src-pages-features-planner-tsx": () => import("./../../../src/pages/features/planner.tsx" /* webpackChunkName: "component---src-pages-features-planner-tsx" */),
  "component---src-pages-features-planner-zh-tsx": () => import("./../../../src/pages/features/planner-zh.tsx" /* webpackChunkName: "component---src-pages-features-planner-zh-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-privacy-zh-tsx": () => import("./../../../src/pages/privacy-zh.tsx" /* webpackChunkName: "component---src-pages-privacy-zh-tsx" */),
  "component---src-pages-productivity-tracking-tool-tsx": () => import("./../../../src/pages/productivity-tracking-tool.tsx" /* webpackChunkName: "component---src-pages-productivity-tracking-tool-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

