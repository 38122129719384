import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { SanitySeo } from '../types/seo';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
  pathname?: string;
  seo?: SanitySeo;
}

const SEO: React.FC<SEOProps> = ({ 
  title, 
  description, 
  image, 
  article = false, 
  pathname,
  seo
}) => {
  // Default values matching siteMetadata from gatsby-config.js
  const defaultTitle = 'Digital All-in-one Planner for Productivity & Time Management | Elisi';
  const defaultDescription = 'Elisi is a user-friendly, cross-device All-in-one Planner to help you improve personal productivity and manage your time effectively. Features include pre-templated modules in a bullet journal along with task planning, habit tracking, project management and note taking. Try Elisi on your iPhone or Android device today!';
  const defaultImage = '/images/og-image.png';
  const siteUrl = 'https://www.elisiapp.com/';
  const keywords = 'Android productivity apps, bullet journal productivity tracker, productivity app, productivity apps for iphone, productivity bullet journal, productivity blog, personal development, time management,  All-in-one Planner, digital bullet journal, best bullet journal, bullet journal iOS, bullet journal iPad, bullet journal Mac, bullet journal Android, digital bullet journaling, planner, notes, habit tracker, productivity';
  const tileColor = '#ffffff';
  const structureData = `
    {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      "name": "Elisi Studios, Inc.",
      "url": "https://www.elisiapp.com/",
      "sameAs": [
        "https://www.facebook.com/Elisiapp/",
        "https://twitter.com/elisiapp"
      ],
      "description": "Elisi is a user-friendly, cross-device All-in-one Planner to help you improve personal productivity and manage your time effectively. Features include pre-templated modules in a bullet journal along with task planning, habit tracking, project management and note taking."
    }`;

  // Use SEO data from Sanity if available, otherwise use props or defaults
  const seoTitle = seo?.metaTitle || seo?.title || title || defaultTitle;
  const seoDescription = seo?.metaDescription || description || defaultDescription;
  const seoImage = seo?.metaImage?.asset?.gatsbyImageData?.images?.fallback?.src || 
                  seo?.openGraph?.image?.asset?.gatsbyImageData?.images?.fallback?.src || 
                  image || 
                  `${siteUrl}${defaultImage}`;
  const seoKeywords = seo?.seoKeywords?.join(", ") || keywords;
  const canonicalUrl = `${siteUrl}${pathname || ''}`;
  const robotsContent = seo?.nofollowAttributes === true ? "noindex, nofollow" : "index, follow";

  // Twitter data
  const twitterTitle = seo?.metaTitle || seo?.title || title || defaultTitle;
  const twitterDescription = seo?.metaDescription || description || defaultDescription;
  const twitterCreator = seo?.twitter?.creator || '';
  const twitterSite = seo?.twitter?.site || '';
  const twitterHandle = seo?.twitter?.handle || '';
  const twitterCardType = seo?.twitter?.cardType || 'summary_large_image';

  // Prepare additional meta tags from Sanity
  const additionalMetaTags: ReactElement[] = [];
  if (seo?.additionalMetaTags) {
    seo.additionalMetaTags.forEach((tag, tagIndex) => {
      if (tag.metaAttributes) {
        tag.metaAttributes.forEach((attr, attrIndex) => {
          if (attr.attributeKey && attr.attributeValueString) {
            additionalMetaTags.push(
              <meta 
                key={`additional-${tagIndex}-${attrIndex}`}
                name={attr.attributeKey} 
                content={attr.attributeValueString} 
              />
            );
          } else if (attr.attributeKey && attr.attributeType === 'image' && 
                    attr.attributeValueImage?.asset?.gatsbyImageData?.images?.fallback?.src) {
            additionalMetaTags.push(
              <meta 
                key={`additional-img-${tagIndex}-${attrIndex}`}
                name={attr.attributeKey} 
                content={attr.attributeValueImage.asset.gatsbyImageData.images.fallback.src} 
              />
            );
          }
        });
      }
    });
  }

  // Generate a unique key for the Helmet component based on the keywords
  // This forces Helmet to re-render when the keywords change
  const helmetKey = `seo-helmet-${seoKeywords}`;

  return (
    <Helmet 
      key={helmetKey}
      htmlAttributes={{ lang: 'en' }}
      title={seoTitle}
      titleTemplate={seoTitle !== defaultTitle ? `%s | ${defaultTitle}` : undefined}
      link={[
        { rel: 'canonical', href: canonicalUrl }
      ]}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: structureData
        }
      ]}
    >
      {/* Include additional meta tags from Sanity */}
      {additionalMetaTags}
      
      {/* Add explicit meta tags for all important meta information */}
      <meta name="description" content={seoDescription} />
      <meta name="keywords" content={seoKeywords} />
      <meta name="robots" content={robotsContent} />
      
      {/* Open Graph tags */}
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      <meta property="og:site_name" content={defaultTitle} />
      
      {/* Twitter Card tags */}
      <meta name="twitter:card" content={twitterCardType} />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={seoImage} />
      
      {/* Additional meta tags */}
      <meta name="msapplication-TileColor" content={tileColor} />
      <meta name="baidu-site-verification" content="codeva-SaygqUj1G5" />
      
      {/* Conditionally add Twitter creator, site, and handle */}
      {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {twitterHandle && <meta name="twitter:handle" content={twitterHandle} />}
    </Helmet>
  );
};

export default SEO; 